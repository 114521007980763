import {
  dehydrate,
  hydrate,
  QueryClient,
  VueQueryPlugin,
} from '@tanstack/vue-query'

export function useTanstack(state) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000,
      },
    },
  })

  if (import.meta.env.SSR) {
    state.vueQueryState = { toJSON: () => dehydrate(queryClient) }
  } else {
    hydrate(queryClient, state.vueQueryState)
  }

  return { VueQueryPlugin, queryClient }
}
